<template>
  <div class="tickets-container">
    <div class="ser-section">
      <div class="text">
        <span class="balance-text">Total Tickets</span>
        <h2 class="user-balance">10</h2>
      </div>

      <nav class="add-cta">
        <ul class="links">
          <button @click="openCreateTicket" class="add-btn btn">
            <i class="fa-solid fa-plus"></i>
            <span>New Ticket</span>
          </button>
        </ul>
      </nav>
      <div class="actions">
        <sweet-modal ref="modal">
          <div v-if="activeModal === 'create'" class="modal-inner">
            <h3 class="create-ticket">Create Ticket</h3>
            <div class="input-wrap">
              <label for="title">Ticket Title</label>
              <input
                placeholder="Eg : Can't find my orders"
                id="title"
                type="text"
                class="input"
              />
            </div>
            <div class="input-wrap">
              <label for="ticket-d">Ticket Title</label>
              <textarea
                placeholder="Eg : please help me check if..."
                id="ticket-d"
                type="text"
                class="input"
              />
            </div>
            <div class="input-wrap">
              <button class="btn">Create Ticket</button>
            </div>
          </div>
          <div v-else-if="activeModal === 'view'" class="modal-inner">
            <h3 class="create-ticket">Issue with payments</h3>
            <div class="chat-me">
              <div class="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                quaerat quos ipsum unde beatae, at omnis. Molestiae, officiis.
                Dolorem, aperiam quasi. Ipsum quos pariatur error dolores beatae
                veniam neque facere!
              </div>
            </div>
            <div class="chat-me">
              <div class="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                quaerat quos ipsum unde beatae, at omnis. Molestiae, officiis.
                Dolorem, aperiam quasi. Ipsum quos pariatur error dolores beatae
                veniam neque facere! lorem7000
              </div>
            </div>
            <div class="chat-support">
              <div class="text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam
                quaerat quos ipsum unde beatae, at omnis. Molestiae, officiis.
                Dolorem, aperiam quasi. Ipsum quos pariatur error dolores beatae
                veniam neque facere! lorem7000
              </div>
            </div>
            <div class="chat-me">
              <div class="text">Lorem</div>
            </div>
            <div class="chat-support">
              <div class="text">no l's</div>
            </div>
            <div class="chat-support">
              <div class="text">L</div>
            </div>
            <div class="chat-me">
              <div class="text">L</div>
            </div>
            <form v-on:submit.prevent class="chat-form">
              <div class="input-section">
                <textarea name="ticket" id="ticket" class="input"></textarea>
              </div>
              <div class="submit-section">
                <button class="btn">Submit</button>
              </div>
            </form>
          </div>
        </sweet-modal>
      </div>
    </div>
    <div class="tickets">
      <span class="tickets-head">Tickets</span>
      <div class="scroll-section">
        <div
          v-for="item in [11, 12, 13, 14, 15, 16]"
          :key="item"
          class="list-item"
          @click="openTicket"
        >
          <div class="left">
            <i class="fa-solid fa-box-open"></i>
            <div class="main-text">
              <span class="top-up-text">Order not working</span>
              <span class="method">Open</span>
            </div>
          </div>
          <div class="right">
            <span class="date">Mar 07, 2022</span>
          </div>
        </div>
        <div
          v-for="item in [111, 121, 131, 141, 151, 161]"
          :key="item"
          class="list-item"
          @click="openTicket"
        >
          <div class="left">
            <i class="fa-solid fa-check-double"></i>
            <div class="main-text">
              <span class="top-up-text">Order not working</span>
              <span class="method">Answered</span>
            </div>
          </div>
          <div class="right">
            <span class="date">Mar 07, 2022</span>
          </div>
        </div>
        <div
          @click="openTicket"
          v-for="item in [1, 2, 3, 4, 5, 6]"
          :key="item"
          class="list-item"
        >
          <div class="left">
            <i class="fa-solid fa-minus"></i>
            <div class="main-text">
              <span class="top-up-text">Top Up</span>
              <span class="method">Closed</span>
            </div>
          </div>
          <div class="right">
            <span class="date">Mar 07, 2022</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tickets",
  metaInfo: {
    title: "The Gainer",
    titleTemplate: "%s | Tickets",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      activeModal: "create",
    };
  },
  methods: {
    openCreateTicket() {
      this.$refs.modal.open();
      this.activeModal = "create";
    },
    openTicket() {
      this.$refs.modal.open();
      this.activeModal = "view";
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
